import styles from "./Switch.module.scss"
import {memo} from "react";

export const Switch = memo(({ isOn, handleToggle, colorOne, colorTwo }) => {
    return (
        <>
            <input
                checked={isOn}
                onChange={handleToggle}
                className={styles.switch__checkbox}
                id={`switch`}
                type="checkbox"
            />
            <label
                style={{ background: isOn ? colorOne : colorTwo }}
                className={styles.switch__label}
                htmlFor={`switch`}
            >
                <span className={styles.switch__button} />
            </label>
        </>
    );
});

